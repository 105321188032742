.code-preview{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background-color: transparent;
    padding: 30px;
    border-radius: 45px;
    border: solid 1px var(--color-gray-400);
    transition: .3s ease;
    cursor: pointer;

    &:hover{
        background-color: #FCFCFF;
        border: 1px solid #CAD6FF;
    }

    @media (max-width: 728px) {
		flex-direction: column;
	}

    &.private{
        opacity: .5;
        pointer-events: none;
    }

    &__tags{
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        @media (max-width: 728px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
        }

    }
}