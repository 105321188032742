.project{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 450px;
    border: solid 1px var(--color-gray-400);
    border-radius: 45px;
    padding: 30px;
    transition: .3s ease;
    cursor: pointer;
    
    & img {
        margin: auto;
        max-width: 300px;
        object-fit: contain;
    }
    
    &:hover{
        border: solid 1px #CAD6FF;
    }
}