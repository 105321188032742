:root {
	--color-black: #222328;
	--color-white: #fefeff;
	--color-light: #f9fafe;
	--color-light-100: rgba(249, 250, 254, 0.75);
	--color-paragraph: rgb(0, 0, 0, 0.75);
	--color-gray-50: #f9f9f9;
	--color-gray-100: #f3f3f3;
	--color-gray-200: #e6ebf4;
	--color-gray-300: #e4e4e4;
	--color-gray-400: #e1e1e1;
	--font-primary: 'Manrope';
}
::selection {
	background-color: #b7c1cc;
	// color: var(--color-light);
}

*,
*::before,
*::after {
	outline-color: #b7c1cc;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	padding: 0px;
	margin: 0px;
	overflow-x: hidden;
	font-family: 'Manrope', sans-serif;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

h1 {
	font-weight: 600;
	font-size: 2.5rem;
}

h2 {
	font-weight: 600;
	font-size: 2rem;
}

h3 {
	font-weight: 600;
	font-size: 1.5rem;
}

h4 {
	font-weight: 600;
	font-size: 1.125rem;

	.sm {
		font-size: 1rem;
	}
}

input,
select,
textarea,
button {
	font-family: 'Roboto', sans-serif;
	color: #0e0e0e;
}


p {
	font-size: 1rem;
	color: var(--color-paragraph);
	line-height: 24px;
	font-weight: 500;
	max-width: 640px;

	&.sm {
		font-size: 0.75rem;
		opacity: 0.75;
	}
}

ul {
	padding: 0px;
	margin: 0px;
	list-style: none;
}

li {
	font-weight: 500;
	color: var(--color-paragraph);
}


.interactive-link{
	color: #5d5fef;
	transition: .3s ease;
	&:hover{
		color: #3234b1;
	}
}


.grid-bg {
	position: absolute;
	top: 0px;
	z-index: -1;
	margin: auto;
	width: 100%;
	max-width: 1030px;

	@media (max-width: 728px) {
		width: 340px;
	}
}

.App {
	max-width: 1030px;
	margin: 100px auto;

	@media (max-width: 728px) {
		margin: 100px 30px;
	}
}

// .text-dark{
// 	color: #000;
// 	font-weight: 600;
// }

.list {
	margin: 0px;
	padding: 0px 15px;
	max-width: 500px;
	list-style-type: '－ ' !important;

	& li {
		margin-bottom: 10px;
		font-size: 0.85rem;
	}
}

.nav {
	margin: 30px 0px;
	display: flex;
	flex-direction: row;
	gap: 20px;
	@media (max-width: 728px) {
		overflow-x: scroll;
	}
}

.nav-fade{
	position: absolute;
	right: 0;
	width: 50px;
	height: 50px;
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
}

.btn {
	border: solid 1px transparent;
	border-radius: 15px;
	padding: 10px;
	width: 130px;
	font-size: 1rem;
	font-weight: 400;
	transition: 0.3s ease;
	cursor: pointer;

	&-primary {
		background-color: transparent;
		border: solid 1px var(--color-gray-400);
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}

		&.active {
			background-color: var(--color-gray-50);
			border: solid 1px var(--color-gray-400);
			opacity: 1;
		}

		@media (max-width: 728px) {
			padding: 10px;
			width: 110px;
			font-size: 0.875rem;
		}
	}
}

.flex-row {
	display: flex;
	flex-direction: row;
	gap: 20px;

	&.r-sm {
		@media (max-width: 728px) {
			flex-direction: column;
		}
	}
}

.justify-content-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.align-items-center {
	align-items: center;
}

.gap {
	&-0 {
		gap: 0px !important;
	}

	&-sm {
		gap: 5px !important;
	}

	&-1 {
		gap: 10px !important;
	}

	&-3 {
		gap: 30px !important;
	}

	&-5 {
		gap: 50px !important;
	}
}

.grid {
	display: grid;
	gap: 30px;

	&.col-3 {
		grid-template-columns: repeat(3, 1fr);
		
		@media (max-width: 728px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	&.col-2 {
		grid-template-columns: repeat(2, 1fr);

		@media (max-width: 728px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}

.m {
	&x-auto {
		margin: 0 auto;
	}
	&-auto {
		margin: auto !important;
	}
}

.mt {
	&-0 {
		margin-top: 0px !important;
	}
	&-1 {
		margin-top: 5px !important;
	}
	&-2 {
		margin-top: 10px !important;
	}
	&-3 {
		margin-top: 15px !important;
	}

	&-5 {
		margin-top: 30px !important;
	}
}

.mb {
	&-0 {
		margin-bottom: 0px !important;
	}
	&-1 {
		margin-bottom: 5px !important;
	}
	&-2 {
		margin-bottom: 10px !important;
	}
	&-3 {
		margin-bottom: 15px !important;
	}

	&-5 {
		margin-bottom: 30px !important;
	}
}

.fade-up {
	animation: fadeUp 0.3s ease both;

	&--3 {
		animation: fadeUp 0.3s 0.3s both ease;
	}

	&--5 {
		animation: fadeUp 0.3s 0.5s both ease;
	}
}

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.mini-circle {
	height: 10px;
	width: 10px;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
}
