.photo-preview{

    &__img{
        display: flex;
        border-radius: 10px;
        height: 400px;
        object-fit: cover;
        border: solid 1px var(--color-gray-400);

        &.lg{
            height: 640px;
            border-radius: 30px;

            @media (max-width: 728px) {
                height: 440px;
            }
        }

        &.md{
            height: 300px;

            @media (max-width: 728px) {
                height: 200px;
            }
        }

        &.normal{
            height: inherit;
            background-color: #f2f2f2;
        }

        &.space{
            padding: 30px;
        }
    }
}